



































































































import {
  defineComponent,
  computed,
  PropType,
  toRefs,
  ref,
  useRouter
} from '@nuxtjs/composition-api'
import {
  faChevronDown,
  faChevronUp,
  faExclamationTriangle,
  faShareAlt,
  faVolumeMute,
  faVolumeUp,
  faHeart as solidHeart
} from '@fortawesome/free-solid-svg-icons'
import { faHeart } from '@fortawesome/free-regular-svg-icons'
import { ShortVideo, Reactions, ShortsClassified } from '~/models/short-video'
import { handleError } from '~/utils/http'
import { AxiosError } from 'axios'
import { useLogger } from '~/compositions/logger'
import { useI18n } from '~/compositions/i18n'
import { useSnackbar } from '~/compositions/snackbar'
import ShortVideosService from '~/services/ShortVideosService'
import { useNamespacedStore } from '~/compositions/store'
import { USER_NS, UserState } from '~/store/modules/shared/user/state'
import { useLoginModal } from '~/compositions/login-modal'
import { useDeps } from '~/compositions/dependency-container'
import UserAgentService from '~/services/UserAgentService'

export default defineComponent({
  components: {
    CShareModal: () =>
      import('~/components/shared/configurable/social/CShareModal.vue'),
    CReportModal: () =>
      import('~/components/shared/configurable/modal/CReportModal.vue')
  },
  props: {
    video: { type: Object as PropType<ShortVideo>, default: false },
    loading: { type: Boolean, default: false },
    muted: { type: Boolean, default: false },
    hiddenLike: { type: Boolean, default: false },
    hiddenNavigation: { type: Boolean, default: false },
    hiddenReport: { type: Boolean, default: false },
    previousDisabled: { type: Boolean, default: false },
    nextDisabled: { type: Boolean, default: false },
    classified: { type: Object as PropType<ShortsClassified>, default: '' }
  },
  setup(props, { emit }) {
    const { video, classified } = toRefs(props)
    const shareModalVisible = ref(false)
    const reportModalVisible = ref(false)
    const { t } = useI18n()
    const snackbar = useSnackbar()
    const logger = useLogger()
    const router = useRouter()
    const { getters: userGetters } = useNamespacedStore<UserState>(USER_NS)
    const { showModal } = useLoginModal({ redirectAfterLogin: false })
    const [userAgentService, shortVideosService] = useDeps(
      UserAgentService,
      ShortVideosService
    )
    const isIos = userAgentService.isIos()
    const isMacOs = userAgentService.isMacOs()

    const isAnon = computed(() => userGetters('isAnon'))

    const icons = {
      previous: faChevronUp,
      next: faChevronDown,
      like: faHeart,
      activeLike: solidHeart,
      share: faShareAlt,
      mute: faVolumeMute,
      volumeNormal: faVolumeUp,
      report: faExclamationTriangle
    }

    const shortUrl = computed(() => {
      if (process.server) {
        return undefined
      }
      return `${window.location.origin}${
        router.resolve({
          name: '__shorts_classified',
          params: { cid: classified.value.id.toString() },
          query: { sid: video.value.id.toString() }
        }).href
      }`
    })

    const myReactions = computed(() => video.value?.myReactions)

    async function createReaction(reaction: Reactions) {
      try {
        await shortVideosService.createReaction(video.value.id, {
          reaction
        })
        emit('create-reaction', { videoId: video.value.id, reaction })
      } catch (error) {
        handleError(error as AxiosError, snackbar, logger, t)
      }
    }
    async function deleteReaction(reaction: Reactions) {
      try {
        const { message } = await shortVideosService.deleteReaction(
          video.value.id,
          reaction
        )

        emit('delete-reaction', { videoId: video.value.id, reaction })

        snackbar.success(message)
      } catch (error) {
        handleError(error as AxiosError, snackbar, logger, t)
      }
    }

    function handleLike() {
      if (isAnon.value) {
        showModal()
        return
      }

      if (!video.value?.myReactions?.like) {
        createReaction(Reactions.LIKE)
      } else {
        deleteReaction(Reactions.LIKE)
      }
    }

    function handleClickReport() {
      if (isAnon.value) {
        showModal()
        return
      }
      reportModalVisible.value = true
    }

    function handleReportVisibleChange(isVisible: boolean) {
      if (!isVisible) {
        emit('close-report')
      }
      reportModalVisible.value = isVisible
    }
    function handleShareVisibilityChange(isVisible: boolean) {
      shareModalVisible.value = isVisible

      if (!isVisible) {
        emit('close-share')
      }
    }
    return {
      icons,
      handleLike,
      myReactions,
      shareModalVisible,
      shortUrl,
      shortVideosService,
      reportModalVisible,
      handleReportVisibleChange,
      handleClickReport,
      isIos,
      isMacOs,
      handleShareVisibilityChange
    }
  }
})
